<template>
  <v-row class="">
    <v-col v-if="loading" cols="12">
      <v-card flat class="shadow2-a" transition="fade-transition">
        <v-card-text class="pa-0">
          <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col v-else cols="12" :class="{'px-0': $vuetify.breakpoint.xs}">
      <v-card class="pa-0 shadow-off" elevation="0">
        <v-card elevation="0" class="shadow-off" :loading="dloading" :disabled="dloading" loader-height="2" v-if="edit">
          <div class="pb-2 pt-5">
            <div class="mb-4">
              <v-progress-linear
                color="success"
                height="5"
                :value="25*step"
                background-color="grey lighten-3"
                striped
              >
              </v-progress-linear>
              <div class="d-flex justify-center progress-label">
                  <v-spacer></v-spacer>
                  <template v-for="(v,k) in ['Step 1', 'Step 2', 'Step 3']">
                    <v-btn :key="k" small :text="step === (k+1)" elevation="0" :class="{'success': step >= (k+1), 'grey lighten-3': step < (k+1)}" @click="step = (step > (k+1))?k+1:step">
                      <v-icon left small v-if="step === (k+1)">mdi-pencil</v-icon>
                      <v-icon left small v-else-if="step > (k+1)">mdi-check</v-icon>
                      {{v}}
                    </v-btn>
                    <v-spacer :key="'s'+k"></v-spacer>
                  </template>
              </div>
            </div>
          </div>
        </v-card>
        <v-stepper v-model="step" class="shadow-off overflow-initial">
          <v-stepper-items class="shadow-off">
            <v-stepper-content step="1" class="pa-0">
              <v-card :disabled="dloading" elevation="0" class="shadow-off">
                <v-card-text class="pb-0">
                  <div class="mb-8 d-flex align-center">
                    <span class="title font-weight-bold" label>1</span>
                    <v-icon class="mx-2">mdi-circle-small</v-icon>
                    <div class="subtitle-2">Upload file & select the heading row</div>
                    <v-spacer></v-spacer>
                    <v-btn :loading="dloading" v-if="file && (typeof (file || {}).name === 'string') && !tableloading"
                    small @click="mapColumns()" color="primary" elevation="0">Next<v-icon right small>mdi-arrow-right</v-icon></v-btn>
                  </div>
                </v-card-text>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" class="pt-0">
                      <lb-file v-model="file" label="Sample File" />
                    </v-col>
                    <v-col cols=3 class="pt-0">
                      <lb-number v-model="form.ignorerows" label="Ignore Rows" min="0"/>
                    </v-col>
                    <v-col cols=3 class="pt-0">
                      <lb-number v-model="form.ignorecolumns" label="Ignore Columns" min="0"/>
                    </v-col>
                    <v-col cols="12" class="pt-0" v-if="(cells || []).length > 0 || tableloading" style="overflow-x:scroll">
                      <div v-if="tableloading">
                        <v-btn color="" loading icon></v-btn>Loading
                      </div>
                      <div v-else-if="typeof (this.file || {}).name === 'string'">
                        <div><strong>Note:</strong> Row in yellow color will be considered as data heading</div>
                        <table class="upload-data-table">
                          <caption class="d-none">upload-data-table</caption>
                          <tbody>
                            <tr>
                              <th scope="#"></th>
                              <template v-for="k in colcount">
                                <th scope="colName" v-if="k-1 >= Math.max((form.ignorecolumns || 0)-1, 0)" :key="k">{{colName(k-1)}}</th>
                              </template>
                            </tr>
                            <tr v-for="(v,k) in displayCells" :key="k">
                              <template v-if="(v instanceof Array)">
                                <td>{{Number(Math.max(Number(form.ignorerows || 0)-5,0))+k+1}}</td>
                                <template v-for="ck in Math.max(v.length,colcount)">
                                  <td v-if="(ck-1) >= Math.max((form.ignorecolumns || 0)-1, 0)" :key="ck" :class="{'selected-cell': (Number(form.ignorerows || 0)-(Math.max(Number(form.ignorerows || 0)-5,0)) === k && (ck-1) >= Number(form.ignorecolumns || 0))}">
                                    <span>{{v[ck-1]}}</span>
                                  </td>
                                </template>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2" class="pa-0">
              <v-card flat :disabled="dloading" class="">
                <v-card-title primary-title class="py-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn v-on="on" small color="primary" class="shadow-off mt-4" outlined @click="step--"><v-icon left>mdi-arrow-left</v-icon> Back to Step 1</v-btn>
                    </template>
                    <span>Previous step</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-btn @click="generateSummary()" color="primary" elevation="0" small><v-icon left small>mdi-file-document-outline</v-icon>View summary</v-btn>
                </v-card-title>
                <v-card-text>
                  <div class="body-1 font-weight-bold pb-2 pt-2">Available Columns in sample uploaded</div>
                  <div class="availablecols-container" v-if="filecolumns.length > 0">
                    <v-chip :draggable="true" @dragstart="prevColOnDrag=-1;dragStart($event, v)" v-for="(v,k) in filecolumns" :key="k" 
                    class="availablecols mr-2 mb-2 shadow-on" outlined label>
                        <v-icon small class="mr-1">mdi-drag</v-icon> 
                        {{v}}
                    </v-chip>
                  </div>
                  <div v-else class="text-left grey--text text--lighten-1 headline">All columns mapped</div>
                </v-card-text>
                <v-card-text>
                  <div class="body-1 font-weight-bold pb-2">Required columns</div>
                  <v-checkbox hide-details class="mt-0" label="Create Unmapped columns" v-model="form.createcolumns"></v-checkbox>
                  <div class="maptable-container">
                    <table class="maptable">
                      <caption class="d-none">maptable</caption>
                      <tbody>
                        <template v-for="(v,k) in displayColMap">
                          <tr :key="k">
                            <td class="mapbox1">
                              {{v.value[0]}}
                            </td>
                            <td class="mapline">
                              <span></span>
                            </td>
                            <td :class="{'mapbox2': true, 'mapboxfilled': v.value[1]}" draggable="true" @drop="dragEnd($event, v.key)" @dragover="allowDrop($event)" @dragstart="prevColOnDrag=v.key;dragStart($event, v.value[1])">
                              <div class="d-flex align-center">
                                <v-icon small v-if="v.value[1]" class="mr-1">mdi-drag</v-icon>
                                <span>{{v.value[1]}}</span>
                                <span class="flex-grow-1"></span>
                                <v-btn icon color="" small v-if="v.value[1]" @click="removeMap(v.key)"><v-icon small>mdi-close</v-icon></v-btn>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-card-text>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="3" class="pa-0">
              <v-card flat :disabled="dloading" class="">
                <v-card-text class="d-flex align-center">
                  <v-tooltip bottom v-if="edit">
                    <template v-slot:activator="{on}">
                      <v-btn v-on="on" small color="primary" class="shadow-off mt-4" outlined @click="step--"><v-icon left>mdi-arrow-left</v-icon> Back to Step 2</v-btn>
                    </template>
                    <span>Previous step</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-btn @click="saveConfig()" color="primary" elevation="0" small
                    v-if="form.file && requiredcolumns.length > 0 && edit"
                  ><v-icon small left>mdi-content-save-outline</v-icon>Save</v-btn>
                  <v-btn @click="edit=true;step=1" color="primary" elevation="0" small v-if="!edit">
                    <v-icon small left>mdi-pencil</v-icon>Reconfigure
                  </v-btn>
                </v-card-text>
                <v-card-text>
                  <div class="body-1 font-weight-bold pb-2">
                    Basic Detail
                    <v-btn icon small class="ml-2" color="primary" @click="step=1" v-if="edit"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </div>
                  <div class=""><strong class="mr-2">File Name:</strong> {{summary.filename}}</div>
                  <div class=""><strong class="mr-2">Ignore rows:</strong> {{form.ignorerows || 0}}</div>
                  <div class=""><strong class="mr-2">Ignore Columns:</strong> {{form.ignorecolumns || 0}}</div>
                </v-card-text>
                <v-card-text>
                  <div class="body-1 font-weight-bold pb-2">
                    Column Mapping
                    <v-btn icon small class="ml-2" color="primary" @click="step=2" v-if="edit"><v-icon small>mdi-pencil</v-icon></v-btn>
                  </div>
                  <table class="summary-table">
                    <caption class="d-none
                    ">summary-table</caption>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold pb-1">
                          As per System
                        </td>
                        <td class="">
                          <span></span>
                        </td>
                        <td class="font-weight-bold pb-1">
                          As per Client
                        </td>
                      </tr>
                      <template v-for="(v,k) in form.columns">
                        <tr :key="k" v-if="v[1]">
                          <td class="mapbox1">
                            {{v[0]}}
                          </td>
                          <td class="mapline">
                            <span></span>
                          </td>
                          <td class="mapbox2">
                            {{v[1]}}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </v-card-text>
                <v-card-text v-if="addcolumns.length > 0">
                  <div class="body-1 font-weight-bold pb-2">
                    Create New Columns
                  </div>
                  <v-chip class="mr-2 mb-2 shadow-on" label outlined v-for="(v,k) in addcolumns" :key="k">{{v}}</v-chip>
                </v-card-text>
                <v-card-text v-if="ignoredcolumns.length > 0">
                  <div class="body-1 font-weight-bold pb-2">
                    Ignore Columns
                  </div>
                  <v-chip class="mr-2 mb-2 shadow-on" label outlined v-for="(v,k) in ignoredcolumns" :key="k">{{v}}</v-chip>
                </v-card-text>
                <v-card-text v-if="filecolumns.length > 0">
                  <div class="body-1 font-weight-bold pb-2">
                    Removed Columns
                  </div>
                  <v-chip class="mr-2 mb-2 shadow-on" label outlined v-for="(v,k) in filecolumns" :key="k">{{v}}</v-chip>
                </v-card-text>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader';
let xlsx = require('xlsx');

export default {
  name: 'configurefiledo',
  data: () => ({
    step: 1,
    pid: "",
    edit: true,
    loadsuccess: true,
    loading: false,
    dloading: false,
    file: null,
    topdata: [],
    cells: [],
    colcount: 0,
    clientlist: [],
    filelist: [],
    partnerlist: [],
    dragData: "",
    prevColOnDrag: -1,
    filecolumns: [],
    columnsmapcounter: 0,
    requiredcolumns: [],
    addcolumns: [],
    ignoredcolumns: [],
    tableloading: false,
    form:{
      file: "",
      ignorerows: "0",
      ignorecolumns: "0",
      columns: [],
      createcolumns: false,
    },
    summary: {
      clientname: "",
      partnername: "",
      filename: ""
    }
  }),
  // components: {
  //   pageHeader
  // },
  created() {
    this.init();
  },
  activated() {
    this.init();

  },
  props: {
    id: {
      type: String,
      default: '',
    },
    config: {
      default: () => {return{}}
    },
    filedata: {},
  },
  methods: {
    init: function(){
      
      this.step = 1;
      this.pid = "";
      this.edit = true;
      this.loadsuccess = true;
      this.loading = false;
      this.dloading = false;
      this.file = null;
      this.topdata = [];
      this.cells = [];
      this.colcount = 0;
      this.clientlist = [];
      this.filelist = [];
      this.partnerlist = [];
      this.dragData = "";
      this.prevColOnDrag = -1;
      this.filecolumns = [];
      this.columnsmapcounter = 0;
      this.requiredcolumns = this.filedata.columns || [];
      this.addcolumns = [];
      this.ignoredcolumns = [];
      this.tableloading = false;
      this.form = {
        file: this.id,
        ignorerows: "0",
        ignorecolumns: "0",
        columns: [],
        createcolumns: true
      };
      this.summary = {
        clientname: "",
        partnername: "",
        filename: ""
      };
      if(this.config.file){
        this.form._id = this.config._id || "";
        this.form.file = this.config.file || "";
        this.form.columns = this.config.columnmap || [];
        this.form.createcolumns = this.config.createcolumns;
        this.form.ignorerows = (this.config.ignorerows || 0).toString();
        this.form.ignorecolumns = (this.config.ignorecolumns || 0).toString();
        this.filecolumns = this.config.unusedcolumns || [];
        this.addcolumns = this.config.addcolumns || [];
        this.ignoredcolumns = this.config.ignoredcolumns || [];
        this.file = null;
        this.step = 2;
        this.edit = false;
        this.generateSummary("name");
      }
    },
    getExistingConfig: function(id, pid){
      this.loading = true;
      this.id = id;
      this.pid = pid;
      this.axios.post('/conserve/fileconfig/get/'+id).then(x => {
        if(x.data.status == "success" && x.data.data.length > 0){
          let v = x.data.data[0] || {};
          console.log("here", v);
          this.form.partner = pid || "";
          return this.getClientList().then(() => {
            console.log("done client");
            this.form.client = v.client || "";
            this.form.file = v.file || "";
            this.form.columns = v.columnmap || [];
            this.form.createcolumns = v.createcolumns;
            this.form.ignorerows = (v.ignorerows || 0).toString();
            this.form.ignorecolumns = (v.ignorecolumns || 0).toString();
            this.filecolumns = v.unusedcolumns || [];
            this.addcolumns = v.addcolumns || [];
            this.ignoredcolumns = v.ignoredcolumns || [];
            this.file = null;
            this.step = 2;
            this.edit = false;
            this.generateSummary("name");
          }).catch(e => {
            console.log(e);
            this.$store.commit("sbError", "Error loading client name");
            this.loadsuccess = false;
          });
        }else {
          this.$store.commit("sbError", x.data.message);
          this.loadsuccess = false;
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || "Error loading file list");
        this.loadsuccess = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    generateTable: function(){
      if(typeof (this.file || {}).name === "string"){
        
        let ext = this.file.name.split(".").pop();
        if(["xlsx", "xls", "csv"].indexOf(ext) > -1){
          if(ext !== "csv" && this.file.size > 1024*1024*2){
            this.file = null;
            this.cells = [];
            this.$store.commit("sbError", "'xlsx/xls' file should be 2MB maximum");
          }else{
            this.tableloading = true;
            let f = this.file;
        
            let fmt = this.file.name.split('.').pop();
            let t = this.form.ignorerows || 0;
           
            let parent = this;
           
            let readCSVFile = new FileReader();
            let headerFile = new FileReader();
            let hf = null;
            readCSVFile.onload = function(e) {
              let d = e.target.result.split('\n');
              if(d.length >= t+1){
                let hd = d.slice(Math.max(t-15,0),Math.min(t+50,d.length));
                parent.topdata = hd;
                hd = hd.join("\n");
                hf = new File([hd], 'tfile', {type:'text/csv'});
                headerFile.readAsBinaryString(hf);
              }
              else{
                throw new "Invalid File";
              }
            }
            readCSVFile.onerror = function() {parent.tableloading = false;this.cells=[];};
            headerFile.onload = function(e) {
              let d = e.target.result;
              let wb = xlsx.read(d, {type: 'binary', cellDates: false, cellFormula: false}); 
              
              let sh = wb.SheetNames[0];
              let dt = xlsx.utils.sheet_to_json(wb.Sheets[sh], {header: 1, raw: true});
              if(fmt != "csv") {
                dt = dt.splice(Math.max(t-15,0),Math.min(t+50,dt.length));
                parent.topdata = dt;
              }
              
              parent.cells = dt;
              parent.tableloading = false;
            }
            headerFile.onerror = function() {parent.tableloading = false;this.cells=[];};
            if(fmt == "csv") readCSVFile.readAsText(f.slice(0, 1024*1024*20));
            else headerFile.readAsBinaryString(f);
          }
        }else this.cells = [];
      }else {
        
        this.cells = [];
      }
      
    },
    mapColumns: function(){
      this.dloading = true;
      let h = this.cells.slice(this.form.ignorerows,this.form.ignorerows+1);
      h = h[0].slice(this.form.ignorecolumns, h[0].length);
      for (let i = 0; i < h.length; i++) {
        h[i] = (h[i] || "").toString().trim();
      }
      let c = [];
      let usedcols = [];
      if(this.requiredcolumns.length > 0){
       
        this.requiredcolumns.forEach(el => {
          let cc = '';
          
          for (let i = 0; i < this.form.columns.length; i++) {
            let e = this.form.columns[i];
            if(e instanceof Array){
              if(e[0].toString() === el.toString() && h.indexOf(e[1].toString()) > -1) {
                cc = e[1];
                usedcols.push(e[1].toString());
                break;
              }
            }
          }
          if(cc === ""){
            if(h.indexOf(el.toString()) > -1) cc = el.toString();
            usedcols.push(el.toString());
          }
          c.push([el.toString(),cc.toString().trim()]);
        });
        this.filecolumns = [];
        h.forEach(el => {
          if(usedcols.indexOf(el.toString()) === -1 && el.toString()) this.filecolumns.push(el.toString());
        });
        this.form.columns = c;
        this.step++;
        this.columnsmapcounter++;
      }else this.$store.commit("sbError", "No required columns found");
      this.dloading = false;
    },
    colName: function(n) {
      let ordA = 'A'.charCodeAt(0);
      let ordZ = 'Z'.charCodeAt(0);
      let len = ordZ - ordA + 1;
    
      let s = "";
      while(n >= 0) {
          s = String.fromCharCode(n % len + ordA) + s;
          n = Math.floor(n / len) - 1;
      }
      return s;
    },
    dragStart: function(e, d){
      this.dragData = d;
    },
    dragEnd: function(e, k){
      e.preventDefault();
      if(this.form.columns[k][1]) this.filecolumns.push(this.form.columns[k][1]);
      this.form.columns[k][1] = this.dragData;
      let fk = this.filecolumns.indexOf(this.dragData);
      if(fk > -1) this.filecolumns.splice(fk, 1);
      else if(this.prevColOnDrag > -1) this.form.columns[this.prevColOnDrag][1] = "";
      this.prevColOnDrag = -1;
      this.dragData = "";
      this.columnsmapcounter++;
    },
    allowDrop: function(e){
      e.preventDefault();
    },
    removeMap: function(k){
      this.filecolumns.push(this.form.columns[k][1]);
      this.form.columns[k][1] = "";
    },
    generateSummary: function(t="all"){
      this.dloading = true;
      this.summary.partnername = "";
      if(this.partnerlist.length > 0){
        if(typeof this.partnerlist[0] === "object" && !(this.partnerlist[0] instanceof Array)){

            for (const i of this.partnerlist) {
              if(i.value === this.form.partner){
              this.summary.partnername = i.name;
              break;
            }
            }
        }else{
          this.summary.partnername = this.form.partner;
        }
      }
      this.summary.filename = this.filedata.name || {};
      if(t === "all"){
        this.addcolumns = [];
        this.ignoredcolumns = [];
        this.form.columns.forEach(el => {
          if(!el[1]){
            if(this.form.createcolumns) this.addcolumns.push(el[0]);
            else this.ignoredcolumns.push(el[0]);
          } 
        });
      }
      this.step++;
      this.dloading = false;
    },
    saveConfig: function(){
      let f = {};
      f._id = this.form._id || "";
      f.ignorecolumns = this.form.ignorecolumns || 0;
      f.ignorerows = this.form.ignorerows || 0;
      f.file = this.form.file || "";
      f.createcolumns = this.form.createcolumns;
      f.columnmap = [];
      f.ignoredcolumns = [];
      f.addcolumns = [];
      this.form.columns.forEach(el => {
        if(el[1]) f.columnmap.push(el.map(x=>x.trim()));
        else{
          if(this.form.createcolumns) f.addcolumns.push(el[0]);
          else f.ignoredcolumns.push(el[0]);
        }
      });
      f.unusedcolumns = [];
      f.unusedcolumns = this.filecolumns;
      this.dloading = true;
      let url = 'v2/ia/config/addfile/' + this.id;
      if(this.edit) url = 'v2/ia/config/editfile/' + this.id;
      this.axios.post(url, {data: [f]}).then(x => {
        if(x.data.status == "success" && (x.data.data.length > 0 || x.data.count > 0)){
          this.$store.commit("sbSuccess", "Configuration Saved");
          this.id = x.data.data[0]._id;
          this.edit = false;
          this.file = null;
        }else this.$store.commit("sbError", x.data.message);
      }).catch(err => {
        this.$store.commit("sbError", err.message || "Error saving configuration");
      }).finally(() => {
        this.dloading = false;
      });
    },
    setColCount(v){
      this.colcount = v;
    }
  },
  computed: {
    displayCells: function(){
      let dt = this.cells.slice(Math.max(Number(this.form.ignorerows || 0)-5,0),Math.min(Number(this.form.ignorerows || 0)+5,this.cells.length));
      this.setColCount(0);
      dt.forEach(el => {
        this.colcount = Math.max(this.colcount, el.length);
      });
      return dt;
    },
    displayColMap: function(){
      let c = [];
      let c2 = [];
      for (let i = 0; i < this.form.columns.length; i++) {
        let el = this.form.columns[i];
        if(el instanceof Array){
          if(!el[1]) c.push({key: i, value: el});
          else c2.push({key: i, value: el});
        }
      }
      return [...c,...c2];
    }
  },
  watch: {
    'form.ignorerows': function(){
      if(Math.abs(this.prevtop - this.form.ignorerows) > 40) this.mapColumns();
      this.prevtop = this.form.ignorerows;
    },
    'file': function(){
      this.generateTable();
    },
  }
}
</script>

<style lang="scss" scoped>
.progress-label{ margin-top: -15px}
.upload-data-table{
  border-collapse: collapse;
  overflow-x: auto;
  & td, th{
    border: 1px solid #bbb;
    padding: 2px 4px;
    max-width: 200px;
  }
  & tr th, td:nth-child(1){
    background-color: #e8e8e8;
    font-weight: bold;
  }
  & .selected-cell{
    background-color: yellow;
  }
}
.maptable-container{
  // background-color: #f8f8f8;
  & .maptable{
    overflow-x: scroll;
    border-spacing: 5px 10px;
    & .mapbox1, .mapbox2{
      min-width: 200px;
      padding-left: 6px;
      padding-right: 6px;
      // margin-top: 5px;
    }
    & .mapbox1{
      padding-top: 5px;
      padding-bottom: 5px;
      border: 1px solid #ddd;
      background-color: #f5f5f5;
    }
    & .mapline{
      min-width: 80px;
      position: relative;
      & span{
        position: absolute;
        height: 2px;
        width: 100%;
        top: 45%;
        left: 0;
        background-color: #ddd; 
      }
    }
    & .mapbox2{
      cursor: -webkit-grab; 
      cursor: grab;
      border: 1px dashed #ddd;
      background-color: #fff;
    }
    & .mapboxfilled{
      border: 1px solid #ddd;
      background-color: #f5f5f5;
    }
  }
}
.summary-table{
  overflow-x: scroll;
  border-spacing: 5px;
  & .mapbox1, .mapbox2{
    min-width: 200px;
    padding: 2px 4px;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
  }
  & .mapline{
    min-width: 80px;
    position: relative;
    & span{
      position: absolute;
      height: 2px;
      width: 100%;
      top: 45%;
      left: 0;
      background-color: #ddd; 
    }
  }
}
.availablecols{
  cursor: -webkit-grab; 
  cursor: grab;
  // padding: 2px 4px;
  // margin-bottom: 4px;
  // margin-right: 4px;
  // border: 1px solid #ddd;
  // background-color: #f5f5f5;
}
</style>